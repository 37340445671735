import React, { forwardRef } from "react";
import { cn } from "@/utils";
import ButtonLoader from "../ButtonLoader";
import { Button as ButtonCore } from "@radix-ui/themes";

const Button = forwardRef(function Button(
  { children, isLoading = false, ...props },
  ref
) {
  return (
    <ButtonCore
      color="red"
      {...props}
      disabled={props.disabled || isLoading}
      ref={ref}
    >
      {isLoading ? <ButtonLoader className={cn("font-inherit")} /> : children}
    </ButtonCore>
  );
});

export default Button;
